import React, { useState, useEffect } from 'react';
const AWS = require("aws-sdk")
const dynamoDB = new AWS.DynamoDB.DocumentClient()

const MainContent = ({ children }) => {
  const [items, setItems] = useState('');

  useEffect(() => {
    setItems('click load for a list of students');
  }, []);

  function handleClick() {
    fetch('https://express-sample.lodamo.com/').then((res) => res.json())
      .then(data => {
        console.log(data);
        setItems(JSON.stringify(data));
      })
      .catch(err => {
        console.error(err)
        setItems(JSON.stringify(err))
      })
  }

  return (
    <main className="py-10">
      <h2>Students</h2>
      <button type="button" className='btn' onClick={handleClick}>
        Load
      </button>
      <p>{items}</p>
      <div className="px-4 sm:px-6 lg:px-8">
        {children}
      </div>
    </main>
  );
};

export default MainContent;
